









































import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "@/components/topNav.vue"

@Component({
	components: {
		topNav
	}
})
export default class Default extends Vue {
	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {}

	mounted() {}
}
